.searchList .search-list-item {
  //display: inline-flex;
  //width: 49%;
  background: #fff;
  border: 1px solid #e4e4e4;
  padding: 20px;
  margin-bottom: 30px;
}

.searchList .search-list-item:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #f44336;
}

.searchList .search-list-item h3 {
  font-size: 18px;
  font-weight: 600;
  color: lighten(#f44336, 5%);
}

.searchList .search-list-item a:hover {
  color: #f44336;
}

.searchList .search-list-item .btn {
  //margin-top: 20px;
  padding: 0;
}

.searchList .search-list-item .btn button {
  background: #fff;
  display: block;
  border-radius: 0;
  width: 100%;
  color: #f44336;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 15px;
  text-align: center;
  border: 1px solid #f44336;
}

.searchList .search-list-item .btn button:hover {
  background: #f44336;
  color: #fff;
  text-decoration: none;
}

.searchList .search-list-item p {
  line-height: 22px;
  color: #333;
  margin: 10px 0 0 0;
}

.searchList {
  .icon, .info, .btn {
    text-align: center;
  }
}

.searchList {
  margin-top: 40px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: stretch;

  .list-item-wrapper {
    //margin: 20px;
    display: flex;
    flex-flow: column wrap;

    .list-item-upper {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      .icon {
        width: 14%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: #f44336;
      }

      .info {
        flex: auto;
        word-wrap: break-word;
        //max-width: 60%;
      }

      .btn {
        flex: 1;
        //max-width: 25%;
      }

    }

    .list-item-bottom {
      width: 20%;
    }
  }
}

.load-more-button {
  width: 100%;
  margin: 20px 0;
  background: #dc3545;
  color: white;
  padding: 8px 0;
  font-size: 16px;
}

.searchList .fa {
  font-size: 3em;
}

@media only screen and (max-width: 520px) {
  .profile-drawer {
    //width: 70%;
  }
}